import httpService from "../../http/requests/app"


export default {
  state: {

  },

  getters: {

  },
  mutations: {

  },

  actions: {
    getDuplicates({commit}){
      return new Promise((resolve,reject) => {
        httpService.getDuplicates()
          .then(response => {

            resolve(response.data);

          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    getNewCustomers(){
      return new Promise((resolve,reject) => {
        httpService.getNewCustomers()
          .then(response => {

            resolve(response.data);

          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    exportNewCustomers({commit}, customerData){
      return new Promise((resolve,reject) => {
        httpService.exportNewCustomers(customerData.email)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve();

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
      verifyCustomerIdentification({commit}, data){
          return new Promise((resolve,reject) => {
              httpService.verifyCustomerIdentification(data.customerId, data.status)
                  .then(response => {

                      if(response.data.statusCode == 200){

                          resolve();

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      updateCustomerTier({commit}, data){
          return new Promise((resolve,reject) => {
              httpService.updateCustomerTier(data.customerId, data.customerTier)
                  .then(response => {

                      if(response.data.statusCode == 200){

                          resolve();

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
    saveDuplicate({commit}, customerData){
      return new Promise((resolve,reject) => {
        httpService.saveDuplicate(customerData)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve();

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    exportCustomers({commit}, customerData){
      return new Promise((resolve,reject) => {
        httpService.exportCustomers(customerData)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve();

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
      transferCustomerEpurse({commit}, customerData){
      return new Promise((resolve,reject) => {
        httpService.transferEpurse(customerData)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve(response.data);


            }
            else{
              reject({message: response.data.message})
            }


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    mergeDuplicate({commit}, customerData){
      return new Promise((resolve,reject) => {
        httpService.mergeDuplicate(customerData)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve();

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    deleteDuplicate({commit}, customerData){
      return new Promise((resolve,reject) => {
        httpService.deleteDuplicate(customerData)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve();

            }
            else{
              reject({message: response.data.message})
            }


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },

    registerCustomer({commit}, customerData){
      return new Promise((resolve,reject) => {
        httpService.registerCustomer(customerData)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    updateCustomerDetails({commit}, customerData){
      return new Promise((resolve,reject) => {
        httpService.updateCustomerDetails(customerData)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },

    getCustomers({commit}, filterData){

      return new Promise((resolve,reject) => {
        httpService.getCustomers(filterData.page, filterData.customerType, filterData.customerStatus,
            filterData.customerTier)
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });

    },
      getCustomerGroups({commit}, filterData) {
          return new Promise((resolve,reject) => {
              httpService.getCustomerGroups(filterData.page)
                  .then(response => {
                      resolve(response.data);
                  })
                  .catch(error => {
                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      getCUGMembers({commit}, customerData){
          return new Promise((resolve,reject) => {
              httpService.getCUGMembers(customerData.customerId)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      removeCUGMember({commit}, customerData){
          return new Promise((resolve,reject) => {
              httpService.removeCUGMember(customerData.customerId)
                  .then(response => {

                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }



                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      exportEpurseReport({commit}, data) {
          return new Promise((resolve,reject) => {
              httpService.exportEpurseReport(data.username)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },

    importCustomers({commit}, customersData){
      return new Promise((resolve,reject) => {
        httpService.importCustomers(customersData)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }



          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },


    searchCustomerByPhoneNumber({commit}, filterData){
      return new Promise((resolve,reject) => {
        httpService.searchCustomerByPhoneNumber(filterData.phone_number)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
      getInstitutionalCustomers({commit}) {
          return new Promise((resolve,reject) => {
              httpService.getInstitutionalCustomers()
                  .then(response => {
                      resolve(response.data);
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },

    downloadStatement({commit},payload){
      return new Promise((resolve,reject) => {
        httpService.downloadStatement(payload)
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
      getReferrals({commit},payload) {
          return new Promise((resolve,reject) => {
              httpService.getReferrals(payload.page)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },

    sendVerificationCode({commit}, customerData){

      return new Promise((resolve,reject) => {
        httpService.sendVerificationCode(customerData.phoneNumber)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve(response.data)

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });

    },


    getCustomerRatings({commit}){
      return new Promise((resolve,reject) => {
        httpService.getCustomerRatings()
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    getCustomerDetails({commit}, customerData){
      return new Promise((resolve,reject) => {
        httpService.getCustomerDetails(customerData.customerId)
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    getCustomerSummary({commit}, customerData){
      return new Promise((resolve,reject) => {
        httpService.getCustomerSummary(customerData.customerId)
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    updateCustomerStatus({commit}, customerData){
      return new Promise((resolve,reject) => {
        httpService.updateCustomerStatus(customerData)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    activatePhoneNumber({commit}, customerData){
      return new Promise((resolve,reject) => {
        httpService.activatePhoneNumber(customerData)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
      createPushNotification({commit}, pushNotificationData){
          return new Promise((resolve,reject) => {
              httpService.createPushNotification(pushNotificationData)
                  .then(response => {

                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      saveInstitution({commit},institutionData) {
          return new Promise((resolve,reject) => {
              httpService.saveInstitution(institutionData)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve()
                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      editInstitution({commit},institutionData) {
          return new Promise((resolve,reject) => {
              httpService.editInstitution(institutionData)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve()
                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      uploadFile({commit}, payload){
          return new Promise((resolve,reject) => {
              httpService.uploadFile(payload)
                  .then(response => {

                      resolve(response);


                  })
                  .catch(error => {
                      reject(error)
                      //reject({message: axios.defaults.headers})
                  })
          });
      }
  }
};
