import httpService from "../../http/requests/app"



export default {
  state: {

  },

  getters: {

  },
  mutations: {

  },

  actions: {

    getHomepageStats({commit},dateFilter){

      return new Promise((resolve,reject) => {
        httpService.getHomepageStats(dateFilter)
          .then(response => {

            resolve(response.data);

          })
          .catch(error => {
            reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
    getOutletCategoryPerformanceStats({commit},dateFilter) {
      return new Promise((resolve,reject) => {
        httpService.getOutletCategoryPerformanceStats(dateFilter)
            .then(response => {

              resolve(response.data);

            })
            .catch(error => {
              reject(error)

              //reject({message: axios.defaults.headers})
            })
      })
    },
    getLoyaltyMembersSummary({commit},dateFilter) {
      return new Promise((resolve,reject) => {
        httpService.getLoyaltyMembersSummary(dateFilter.startDate, dateFilter.endDate)
            .then(response => {

              resolve(response.data);

            })
            .catch(error => {
              reject(error)

              //reject({message: axios.defaults.headers})
            })
      })
    },
    getMerchantsSummary({commit},dateFilter) {
      return new Promise((resolve,reject) => {
        httpService.getMerchantsSummary()
            .then(response => {

              resolve(response.data);

            })
            .catch(error => {
              reject(error)

              //reject({message: axios.defaults.headers})
            })
      })
    },
    getTopPerformingOutlets({commit}){
      return new Promise((resolve,reject) => {
        httpService.getTopPerformingOutlets()
          .then(response => {

            resolve(response.data);

          })
          .catch(error => {
            reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    }

  }
};
